<template>
  <div>
    <OrderExcel :typeExcel="1" />
  </div>
</template>

<script>
import OrderExcel from "./OrderExcel.vue";
export default {
  data() {
    return {};
  },
  components: { OrderExcel },
};
</script>
